<template>
  <b-card no-body>
    <index-header :search-data="searchData" />
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(company)="data">
        <b-link
          class="text-dark"
          :to="$route.path + '/view/' + data.item.room_code"
        >
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              :text="data.item.avatar"
              variant="primary"
              class="mr-1"
              size="50"
            />
            <div>
              <div>
                {{ data.item.company }}
              </div>
              <div class="font-small-2 text-primary">
                {{ data.item.customer_account }}
              </div>
              <div class="font-small-2 font-italic text-muted">
                <template v-if="data.item.modified">
                  {{ moment(data.item.modified).format('DD.MM.YYYY') }}
                </template>
              </div>
            </div>
          </div>
        </b-link>
      </template>
      <template #cell(catalog)="data">
        <div>
          {{ data.item.catalog }}
        </div>
        <div class="font-small-2 text-primary">
          {{ moment(data.item.expiry_date).format('DD.MM.YYYY') }}
        </div>
        <div class="font-small-2 text-warning">
          <span v-if="moment(data.item.expiry_date).diff(moment(),'days') > 0">
            {{ moment(data.item.expiry_date).diff(moment(),'days') }} gün kaldı.
          </span>
          <span v-else>
            Katalog geçerlilik süresi dolmuş.
          </span>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="primary"
            :to="'/app/messages/view/' + data.item.room_code"
          >
            <FeatherIcon icon="EyeIcon" /> Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BPagination, BCardFooter, BAvatar, BLink, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndexHeader from '@/views/Admin/Messages/elements/IndexHeader.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BAvatar,
    BLink,
    BButton,
    IndexHeader,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'catalog',
          label: 'Katalog',
          thClass: 'text-nowrap width-200',
          tdClass: 'text-nowrap width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
          tdClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        where: {
          'chat_messages.closed !=': null,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['chatMessages/dataList']
    },
    dataCounts() {
      return this.$store.getters['chatMessages/dataCounts']
    },
    chatSearch() {
      return this.$store.getters['chatMessages/searchData']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('chatMessages/getDataList', this.dataQuery)
    },
    searchData() {
      this.dataQuery.start = 0
      this.currentPage = 1
      if (this.chatSearch.keyword) {
        this.dataQuery.or_like = {
          'catalogs.title': this.chatSearch.keyword,
          'customers.company': this.chatSearch.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.getDataList()
    },
  },
}
</script>
